import * as React from 'react'

//import {Link} from 'gatsby'
import Layout from '../components/layout/layout'
//import {StaticImage} from 'gatsby-plugin-image'

const pageTitle = "International Management Association IMA :: Activity Schedule"


const ActivityPage = () => {
    return (
        <Layout pageTitle={pageTitle}>
            <div className="span-23 last" style={{ overflow: "hidden" }}>
                <div className="span-1">&nbsp;</div>
                <div className="span-21 last" style={{ overflow: "hidden" }}>
                    <div className="span-21 last" id="breadcrumb">
                        <a href="/">Home</a> &gt; Activity Schedule
                    </div>

                    <div className="span-6">
                        <img src="/lookandfeel/images/titles/activity_schedule.gif" border="0" alt="Student Experience"
                             style={{marginBottom: "20px"}}/>
                    </div>

                    <div className="span-15 last"><img src="/lookandfeel/images/photos/activity_schedule.jpg" border="0"
                                                       style={{marginBottom: "20px"}}/>
                    </div>

                    <div className="span-21 last">

                        <div style={{ lineHeight: "1.5em" }}>

                            <div className="span-8" style={{ marginBottom: "10px" }}>
                                <u>Activity</u>

                            </div>
                            <div className="span-5">
                                <u>Location</u>
                            </div>
                            <div className="span-3">
                                <u>Date</u>
                            </div>
                            <div className="span-3">
                                <u>Time</u>
                            </div>

                            <div className="span-2 last">
                                &nbsp;
                            </div>
                            <br clear="all"/>

                            <div className="span-8" style={{ marginBottom: "10px"}}>
                                <span
                                    style={{ color: "#ae312f", fontWeight: "bold" }}>Reunion Lunch of HU class and Alumni</span>
                            </div>
                            <div className="span-5">
                                New World Millennium Hong Kong Hotel&nbsp;
                            </div>
                            <div className="span-3">
                                May 26, 2018 (Sat)&nbsp;
                            </div>
                            <div className="span-3">
                                12:00nn-2:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/60/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8" style={{ marginBottom: "10px" }}>
                                <span
                                    style={{ color: "#ae312f", fontWeight: "bold" }}>Invitation to HU Gathering Dinner</span>
                            </div>
                            <div className="span-5">
                                The Empire Centre, TST East&nbsp;
                            </div>
                            <div className="span-3">
                                February 2, 2018 (Fri)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/59/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8" style={{marginBottom: "10px"}}>
                                            <span
                                                style={{color: "#ae312f", fontWeight: "bold"}}>Business Seminar - Business &amp; Society</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                January 12, 2018 (Fri)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm - 9:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/58/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8" style={{ marginBottom: "10px" }}>
                                <span style={{color: "#ae312f", fontWeight: "bold"}}>Business Seminar - Principles of Economics</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                December 15, 2017 (Fri)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm - 9:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/57/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8" style={{ marginBottom: "10px" }}>
                                <span style={{color: "#ae312f", fontWeight: "bold"}}>傑出校友講座系列: HR不會說的求職及面試技巧</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                November 23, 2017 (Thu)&nbsp;
                            </div>
                            <div className="span-3">
                                7:30pm - 9:00pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/54/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8" style={{marginBottom: "10px"}}>
                                <span style={{ color: "#ae312f", fontWeight: "bold" }}>Business Seminar - Human Resource Management</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                November 17, 2017 (Fri)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm - 9:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/55/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8" style={{marginBottom: "10px"}}>
                                <span style={{ color: "#ae312f", fontWeight: "bold" }}>Business Seminar - Communication for Manager</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                October 13, 2017 (Fri)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm - 9:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/56/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8" style={{marginBottom: "10px"}}>
                                <span style={{ color: "#ae312f", fontWeight: "bold" }}>免費講座: 幼稚園入學指南及面試技巧</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                September 26, 2017 (Tue)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm - 9:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/49/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8" style={{marginBottom: "10px"}}>
                                <span
                                    style={{ color: "#ae312f", fontWeight: "bold" }}>Business Seminar - Small Business &amp; Entrepreneurship</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                September 15, 2017 (Fri)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm - 9:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/51/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8"
                                 style={{ marginBottom: "10px" }}>
                                                                        <span
                                                                            style={{
                                                                                color: "#ae312f",
                                                                                fontWeight: "bold"
                                                                            }}>Business Seminar - Principles of Management</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                August 18, 2017 (Fri)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm - 9:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/50/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8"
                                 style={{ marginBottom: "10px" }}>
                                                                            <span
                                                                                style={{
                                                                                    color: "#ae312f",
                                                                                    fontWeight: "bold"
                                                                                }}>Business Seminar - Marketing Management</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                July 21, 2017 (Fri)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm - 9:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/48/">Register</a>
                            </div>
                            <br clear="all"/>

                            <div className="span-8"
                                 style={{ marginBottom: "10px" }}>
                                                                                <span
                                                                                    style={{
                                                                                        color: "#ae312f",
                                                                                        fontWeight: "bold"
                                                                                    }}>Facebook 網絡行銷-創業入門攻略</span>
                            </div>
                            <div className="span-5">
                                Hopkins Education Center&nbsp;
                            </div>
                            <div className="span-3">
                                July 14, 2017 (Fri)&nbsp;
                            </div>
                            <div className="span-3">
                                7:00pm - 9:30pm&nbsp;
                            </div>

                            <div className="span-2 last">
                                <a href="/activity-schedule/register/47/">Register</a>
                            </div>
                            <br clear="all"/>


                        </div>

                        <p>&nbsp;</p>

                    </div>
                    <div className="span-1 last">&nbsp;</div>
                </div>


            </div>

        </Layout>
    )
}

export default ActivityPage
